import React, {useEffect, useState} from "react";
import {Box, Button, Dialog, DialogContent, IconButton, Stack, Typography} from "@mui/material";
import {QuestionSwitch} from "./Questions/QuestionSwitch";
import {firestore} from "../firebase/clientApp";
import {useParams} from "react-router-dom";
import {Close} from "@mui/icons-material";
import pleshDark from "../theme/pleshDark";


export const PinnedQuestions = ({questions}) => {
    const [selected, setSelected] = useState('')
    const {id} = useParams()

    const [elementPlaying, setElementPlaying] = useState({})

    useEffect(() => {
        selected !== '' ? getElementPlaying() : setElementPlaying({})
    }, [selected]);

    function getElementPlaying() {
        firestore.collection('users/plesh/events/'+id+'/queue').doc(selected).onSnapshot(s => {
            setElementPlaying({...s.data(), id: s.id})
            return s.data()
        })
    }

    return (
        <Stack mt={'auto'} px={4}>
            {selected !== '' &&
                <Dialog open={selected !== ''} onClose={() => setSelected('')} maxWidth={'md'}
                        fullWidth={true}
                        fullScreen={elementPlaying.tipo === 'color'}
                >
                <DialogContent id={'a'+selected} /*id={'pinnedQuestionDialogBg'}*/>
                    <Box sx={{textAlign:'right'}}>
                        <IconButton onClick={() => setSelected('')}
                                    sx={{
                                        color: elementPlaying.tipo === 'color' ? pleshDark.palette.getContrastText(elementPlaying.color) : '#000'
                                    }}>
                            <Close sx={{ color: elementPlaying.tipo === 'color' ? pleshDark.palette.getContrastText(elementPlaying.color) : '#000' }} />
                        </IconButton>
                    </Box>
                    <Typography textAlign={'center'} variant={'h4'}
                                sx={{color: elementPlaying.tipo === 'color' ? pleshDark.palette.getContrastText(elementPlaying.color) : 'inherit'}}
                    >
                        {elementPlaying.domanda}
                    </Typography>
                    <QuestionSwitch elementPlaying={elementPlaying}/>
                </DialogContent>
            </Dialog>}
            <Stack spacing={2}>
                {
                    questions.docs.sort((a, b) => a.data().position - b.data().position).map((q, i) => {
                            return <Button key={i} variant={'home-question'}
                                           sx={{
                                               background: q.data().tipo === 'color' ? q.data().color : '',
                                               color: q.data().tipo === 'color' ? pleshDark.palette.getContrastText(q.data().color) : ''
                                            }}
                                    onClick={() => setSelected(q.id)}>
                                {q.data().domanda}
                            </Button>
                        }
                    )
                }
            </Stack>
        </Stack>
    )
}