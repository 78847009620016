import React from "react";
import {List, ListItem, styled, Typography, useTheme} from "@mui/material";
import {THEME_CONFIG} from "../../../config";
import pleshDark from "../../../theme/pleshDark";

const {poll} = THEME_CONFIG

export default function QuizQuestion({risposte, corretta, hideResult = false, answer, sendAnswer, isPoll}) {
    const RispostaStyled = styled(ListItem)({
        border: `2px solid ${poll.default}`,
        borderRadius: '0',
        background: poll.default,
        margin: '1rem 0',
        cursor: 'pointer'
    })

    const computeBkg = (r) => {
        if(!isPoll && hideResult === false) {
            if (answer !== '') {
                if (corretta === r) {
                    return poll.correct
                } else if (answer === r  && answer !== corretta){
                    return poll.wrong
                }
            }
        }

        if(answer === r) {
            return poll.answered
        } else {
            return poll.default
        }
    }

    const Risposte = () => (
        risposte.map((r, i) => {
            return <RispostaStyled key={i} style={{
                width: '100%',
                background: computeBkg(r),
                border: computeBkg(r) ? '2px solid '+ computeBkg(r) : `2px solid ${poll.default}`,
                color: pleshDark.palette.getContrastText(computeBkg(r) || poll.default,),
                boxShadow: '0px 6px 5px rgba(0, 0, 0, 0.3)',
            }} onClick={() => rispondi(r)}>
                <Typography variant={"h6"} fontWeight='400' textAlign={'center'} width={'100%'}>
                    {r}
                </Typography>
            </RispostaStyled>
        })
    )

    const rispondi = (r) => {
        if(isPoll) {
            sendAnswer(r)
        } else {
            if(answer === ''){
                sendAnswer(r)
            }
        }
    }

    return (
        <List>
            <Risposte/>
        </List>
    )
}
